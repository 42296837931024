export const equipmentTypeList = [
  { value: 0, label: "Head", color: "#00B8D9" },
  { value: 1, label: "Top", color: "#0052CC" },
  { value: 2, label: "Bottom", color: "#5243AA" },
  { value: 3, label: "Shoes", color: "#FF5630" },
  { value: 4, label: "Right Hand", color: "#FF8B00" },
  { value: 5, label: "Left Hand", color: "#FFC400" },
  { value: 6, label: "Accessory", color: "#36B37E" },
  { value: 7, label: "Pet", color: "#00875A" },
  { value: 8, label: "Earring", color: "#253858" },
  { value: 9, label: "Necklace", color: "#253858" },
  { value: 10, label: "Bracelet", color: "#253858" },
  { value: 11, label: "Ring", color: "#253858" },
];

export const cardSlotType = [
  { value: 0, label: "Head", color: "#00B8D9" },
  { value: 1, label: "Top", color: "#0052CC" },
  { value: 2, label: "Bottom", color: "#5243AA" },
  { value: 3, label: "Shoes", color: "#FF5630" },
  { value: 4, label: "Right Hand", color: "#FF8B00" },
  { value: 5, label: "Left Hand", color: "#FFC400" },
  { value: 6, label: "Accessory", color: "#36B37E" },
];
