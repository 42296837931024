import React, { Component } from "react";
import { DataContext } from "../context";
import Select from "react-select";
import { Grid } from "@mui/material";
import { Pagination } from "@material-ui/lab";
import { MDBContainer, MDBModal, MDBCol, MDBRow, MDBIcon, MDBInput } from "mdbreact";
import NavBar from "components/NavBar";
import LoadingDialog from "components/dialogs/LoadingDialog";
import ItemDetailTable from "components/itemDetailTable";
import ItemDetailDialog from "components/dialogs/ItemDetailDialog";
import jobTypeList from "data/jobTypeList.json";
import { jobdbmap } from "data/jobdbmap";
import jobTypeListNew from "data/jobTypeListNew.json";
import itemTypeList from "data/itemTypeList.json";
import mapCollection from "data/mapCollection.json";
import monsterElements from "data/monsterElements.json";
import monsterElementsConvert from "data/monsterElementsConvert.json";
import optionStatusList from "data/optionStatusList.json";
import { colorStyles } from "../components/colorStyles";
import { equipmentTypeList, cardSlotType } from "data/equipmentTypeList";
import "../sass/monster_wiki_page.scss";

export default class MonsterWikiPage extends Component {
  static contextType = DataContext;

  constructor(props) {
    super(props);

    this.state = {
      mapCollection: [],
      monsters: [],
      totalPage: 0,
      monsterName: null,
      minLevel: 0,
      maxLevel: 300,
      elementIdx: null,
      mapId: null,
      page: 1,
      isOpenItemDetailDialog: false,
      selectItemId: 0,
      isLoading: false,
    };
  }

  elementResistanceItem = (element: string, resistance: string) => {
    let color = "white";

    if (parseInt(resistance) > 100) color = "green";
    else if (parseInt(resistance) < 100) color = "red";

    return (
      <tr>
        <th>{element}</th>
        <td>
          <p style={{ color: color }}>{resistance}%</p>
        </td>
      </tr>
    );
  };

  handleOnFilterChange = async (
    monsterName: string,
    minLevel: number,
    maxLevel: number,
    elementId: number,
    mapId: number,
    page: number
  ) => {
    const { onFetchMonsters } = this.context;
    let filterMonsterName = monsterName ?? this.state.monsterName;
    let filterMinLevel = minLevel ?? this.state.minLevel;
    let filterMaxLevel = maxLevel ?? this.state.maxLevel;
    let filterElementId = elementId ?? this.state.elementIdx;
    let filterMapId = mapId ?? this.state.mapId;
    let filterPage = page ?? this.state.page;

    this.setState({ isLoading: true });

    let response = await onFetchMonsters(
      filterMonsterName,
      parseInt(filterMinLevel),
      parseInt(filterMaxLevel),
      parseInt(filterElementId),
      parseInt(filterMapId),
      parseInt(filterPage)
    );

    this.setState({ isLoading: false });

    this.setState({ monsters: response.data.monsters, totalPage: response.data.totalPage });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  handleOnDisplayMonsterTable = () => {
    const { monsters } = this.state;

    if (monsters.length > 0) {
      return monsters.map((monster, idx) => {
        return (
          <table className="monster-detail-table">
            <tbody>
              <tr className="monster-title-row">
                <th>{monster.name} #{monster?.id ?? ""}</th>
                <th>ON MAP</th>
                <th>ELEMENT</th>
              </tr>
              <td className="monster-wiki-divider" colSpan={3}>
                <div />
              </td>
              <tr className="monster-detail-row">
                <td>
                  <table className="monster-status-col">
                    <tbody>
                      <tr>
                        <th>HP</th>
                        <td>
                          <p>{monster.hp}</p>
                        </td>
                        <td colSpan={2} rowSpan={6} style={{ background: "white", borderRadius: 7, paddingLeft: 15 }}>
                          <img src={`/assets/monster_model/${monster.modelId}.gif`} />
                        </td>
                      </tr>
                      <tr>
                        <th>LEVEL</th>
                        <td>
                          <p>{monster.level}</p>
                        </td>
                      </tr>
                      <tr>
                        <th>RANGE</th>
                        <td>
                          <p>{monster.range}</p>
                        </td>
                      </tr>
                      <tr>
                        <th>ELEMENT</th>
                        <td>
                          <p>{monsterElementsConvert.elements[monster.status].toUpperCase()}</p>
                        </td>
                      </tr>
                      <tr>
                        <th>CRI</th>
                        <td>
                          <p>{monster.critical}</p>
                        </td>
                      </tr>
                      <tr>
                        <th>DEX</th>
                        <td>
                          <p>{monster.hit}</p>
                        </td>
                      </tr>
                      <tr>
                        <th>EVR</th>
                        <td>
                          <p>{monster.evasion}</p>
                        </td>
                      </tr>
                      <tr>
                        <th>ATK</th>
                        <td>
                          <p>{monster.attack}</p>
                        </td>
                        <th>REBIRTH (S)</th>
                        <td>
                          <p>{monster.rebirth}</p>
                        </td>
                      </tr>
                      <tr>
                        <th>DEF</th>
                        <td>
                          <p>{monster.defence}</p>
                        </td>
                        <th>BASE EXP</th>
                        <td>
                          <p>{monster.exp}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td className="monster-location-col">
                  <div className="location">
                    {monster.mapList.map((map) => (
                      <div className="map">
                        <img src={"assets/map-marker-icon.png"} height="20" />
                        <span>{map.name}&emsp;</span>
                      </div>
                    ))}
                  </div>
                </td>
                <td className="monster-element-col">
                  <table>
                    <tbody>
                      {monster.elementResistance.map((resistance, idx) =>
                        this.elementResistanceItem(monsterElementsConvert.elements[idx], resistance)
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
              <td className="monster-wiki-divider" colSpan={3}>
                <div />
              </td>
              <tr>
                <td colSpan={3}>
                  <table className="drop1-table" style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <th colSpan={3}>COMMON, ITEM , POTION ( 80% )</th>
                      </tr>
                      <tr>
                        <div className="common-drop-table">
                          {monster.drop1.map((drop) => {
                            return (
                              <>
                                <div className="drop-detail">
                                  <button
                                    className="drop-detail-btn"
                                    onClick={(e) =>
                                      this.setState({ isOpenItemDetailDialog: true, selectItemId: drop.item.id })
                                    }
                                  >
                                    <img src={`/assets/icon/${drop.item.iconId}.png`} />
                                    <span>{drop.item.name}</span>
                                    <span> ({drop.prob}%)</span>
                                  </button>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <td className="monster-wiki-divider" colSpan={3}>
                <div />
              </td>
              <tr>
                <td colSpan={3}>
                  <table>
                    <tbody>
                      <tr>
                        <th colSpan={4}>GEM, SCROLL, CARD ( 10% )</th>
                      </tr>
                      <tr>
                        <div className="common-drop-table">
                          {monster.drop2.map((drop) => {
                            return (
                              <>
                                <div className="drop-detail">
                                  <button
                                    className="drop-detail-btn"
                                    onClick={(e) =>
                                      this.setState({ isOpenItemDetailDialog: true, selectItemId: drop.item.id })
                                    }
                                  >
                                    <img src={`/assets/icon/${drop.item.iconId}.png`} />
                                    <span>{drop.item.name}</span>
                                    <span> ({drop.prob}%)</span>
                                  </button>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <td className="monster-wiki-divider" colSpan={3}>
                <div />
              </td>
              <tr>
                <td colSpan={3}>
                  <table>
                    <tbody>
                      <tr>
                        <th colSpan={4}>EQUIPMENT ( 10% )</th>
                      </tr>
                      <tr>
                        <div className="common-drop-table">
                          {monster.drop3.map((drop) => {
                            return (
                              <>
                                <div className="drop-detail">
                                  <button
                                    className="drop-detail-btn"
                                    onClick={(e) =>
                                      this.setState({ isOpenItemDetailDialog: true, selectItemId: drop.item.id })
                                    }
                                  >
                                    <img src={`/assets/icon/${drop.item.iconId}.png`} />
                                    <span>
                                      {drop.item.name}({drop.prob}%)
                                    </span>
                                  </button>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        );
      });
    } else {
      return (
        <div className="no-monster-data">
          <p>ไม่พบข้อมูลมอนเตอร์</p>
        </div>
      );
    }
  };


  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  loadParamUrl = async () => {
      await this.sleep(200);

      let qs = document.location.search;
      qs = qs.split('+').join(' ');
      var params = {},
          tokens,
          re = /[?&]?([^=]+)=([^&]*)/g;

      while (tokens = re.exec(qs)) {
          params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
      }

      console.log(params);
    
      if(params?.monster !== undefined){
        this.setState({ monsterName: params?.monster});
        this.handleOnFilterChange(params?.monster, null, null, null, null, 1);
      }
      else{
        this.handleOnFilterChange(null, 0, 275, null, null, 1);
      }

    return null;
  }

  componentDidMount() {
    this.loadParamUrl();
    // this.handleOnFilterChange(null, 0, 275, null, null, 1);
  }

  render() {
    // const { fetchItemList, equipmentIdList, jobId, itemListResponse, itemType, itemTypeId } = this.context;
    const { mapCollection } = this.context;
    const { monsters } = this.state;

    return (
      <MDBContainer className="monster-wiki-container" fluid>
        <LoadingDialog isOpen={this.state.isLoading} />
        <ItemDetailDialog
          isOpen={this.state.isOpenItemDetailDialog}
          onToggle={() => this.setState({ isOpenItemDetailDialog: !this.state.isOpenItemDetailDialog })}
          itemId={this.state.selectItemId}
        />
        {/* NAME FILTER SECTION */}
        <div className="monster-filter">
          <div className="filter-title">
            <span>NAME FILTER</span>
          </div>
          <div className="filter-input">
            <MDBInput
              autocomplete="off"
              type="text"
              value={this.state.monsterName}
              outline
              onChange={(e) => {
                this.setState({ monsterName: e.target.value });
                this.handleOnFilterChange(e.target.value, null, null, null, null, 1);
              }}
            />
          </div>
        </div>
        {/* LEVEL FILTER SECTION */}
        <div className="monster-filter">
          <div className="filter-title">
            <span>LEVEL FILTER</span>
          </div>
          <div className="range-filter-group">
            <div className="range-filter">
              <div className="range-filter-title">
                <span>Min. Level</span>
              </div>
              <MDBInput
                type="number"
                value={this.state.minLevel}
                outline
                onChange={(e) => {
                  let filterMinLevel = parseInt(e.target.value) ?? 0;
                  this.setState({ minLevel: filterMinLevel });
                  this.handleOnFilterChange(null, filterMinLevel, null, null, null, 1);
                }}
              />
            </div>
            <div className="range-filter">
              <div className="range-filter-title">
                <span>Max. Level</span>
              </div>
              <MDBInput
                type="number"
                value={this.state.maxLevel}
                outline
                onChange={(e) => {
                  this.setState({ maxLevel: e.target.value });
                  this.handleOnFilterChange(null, null, parseInt(e.target.value), null, null, 1);
                }}
              />
            </div>
          </div>
        </div>

        {/* ELEMENT FILTER SECTION */}
        <div className="monster-filter">
          <div className="filter-title">
            <span>ELEMENT FILTER</span>
          </div>
          <div className="filter-input">
            <Select
              className="element-filter"
              defaultValue={monsterElements.elements[0]}
              options={monsterElements.elements}
              getOptionLabel={(option) => option.label.toUpperCase()}
              onChange={(itemOption) => {
                this.setState({ elementIdx: itemOption.value });
                this.handleOnFilterChange(null, null, null, parseInt(itemOption.value), null, 1);
              }}
            />
          </div>
        </div>

        {/* MAP FILTER SECTION */}
        <div className="monster-filter">
          <div className="filter-title">
            <span>MAP FILTER</span>
          </div>
          <div className="filter-input">
            <Select
              className="element-filter"
              defaultValue={mapCollection[0]}
              options={mapCollection}
              getOptionLabel={(option) => option.label.toUpperCase()}
              onChange={(itemOption) => {
                this.setState({ mapId: itemOption.value });
                this.handleOnFilterChange(null, null, null, null, parseInt(itemOption.value), 1);
              }}
            />
          </div>
        </div>
        {/* MONSTER TABLE */}
        <div className="monster-data-container">{this.handleOnDisplayMonsterTable()}</div>
        {/* PAGINATION */}
        <Pagination
          count={this.state.totalPage}
          color="primary"
          page={this.state.page}
          onChange={(evt, page) => {
            this.setState({ page: parseInt(page) });
            this.handleOnFilterChange(null, null, null, null, null, parseInt(page));
          }}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "3vh",
          }}
        />
      </MDBContainer>
    );
  }
}
