import React, { Component } from "react";
import { MDBNavbar } from "mdbreact";
import { BrowserRouter } from "react-router-dom";
import "sass/navbar.scss";
import { NavBarOption } from "enums/navbar-option";

export default class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMenu: NavBarOption.ITEMS,
    };
  }

  handleOnSelectMenu = (menu: string) => {
    this.props.onSelectMenu(menu);
    this.setState({ selectedMenu: menu });
  };

  buildNavbarMenuButton = (keyword: string) => {
    const { selectedMenu } = this.state;

    return (
      <button
        className="navbar-item"
        isSelected={selectedMenu == keyword ? "true" : "false"}
        onClick={(e) => this.handleOnSelectMenu(keyword)}
        style={{
          background: selectedMenu != keyword ? "#3b3b3b" : "#ffffff21",
          color: "wheat",
        }}
      >
        {keyword}
      </button>
    );
  };

  render() {
    const { selectedMenu } = this.state;

    return (
      <>
      <div className="navbar">
        <div className="navbar-title">
          <span>SEAL WIKI</span>
        </div>
        {Object.keys(NavBarOption).map((key, i) => this.buildNavbarMenuButton(key))}
      </div>

      <div className="redirect text-center">
        <a href="https://duran-seal.com/" target="_blank" rel="noopener">
          
        </a>
      </div>
      </>
    );
  }
}
