import React, { Component } from "react";
import Select from "react-select";
import { DataContext } from "../context";
import { Pagination } from "@material-ui/lab";
import { MDBContainer, MDBModal, MDBCol, MDBIcon } from "mdbreact";

import NavBar from "components/NavBar";
import ItemWikiPage from "screens/ItemWikiPage";
import MonsterWikiPage from "screens/MonsterWikiPage";
import PetWikiPage from "./PetWikiPage";
import SkillPage from "./SkillPage";
import "../sass/home.scss";
import { NavBarOption } from "enums/navbar-option";

export default class Home extends Component {
  static contextType = DataContext;

  constructor(props) {
    super(props);
    this.state = {
      menu: "ITEMS",
    };
  }

  handleOnSelectMenu = () => {
    switch (this.state.menu) {
      case NavBarOption.ITEMS:
        return <ItemWikiPage />;
      case NavBarOption.MONSTER:
        return <MonsterWikiPage />;
      case NavBarOption.PETS:
        return <PetWikiPage />;
      case NavBarOption.SKILL:
        return <SkillPage />;
      default:
        return <ItemWikiPage />;
    }
  };

  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  loadParamUrl = async () => {
      await this.sleep(200);

      let qs = document.location.search;
      qs = qs.split('+').join(' ');
      var params = {},
          tokens,
          re = /[?&]?([^=]+)=([^&]*)/g;

      while (tokens = re.exec(qs)) {
          params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
      }
    
      if(params?.item !== undefined){
        this.setState({ menu: 'ITEMS' })
      }
      else if(params?.monster !== undefined){
        this.setState({ menu: 'MONSTER' })
      }
      else{
        this.setState({ menu: 'ITEMS' })
      }

    return null;
  }

  componentDidMount() {
    const { onFetchMapCollection } = this.context;
    onFetchMapCollection();

    this.loadParamUrl();
  }

  componentWillUnmount() {}

  render() {
    return (
      <MDBContainer
        fluid
        style={{
          padding: 0,
          height: "100%",
        }}
      >
        <NavBar onSelectMenu={(menu) => this.setState({ menu: menu })} />
        <div className="wiki-container">{this.handleOnSelectMenu()}</div>
      </MDBContainer>
    );
  }
}
