import logo from "./logo.svg";
import "./App.css";
import Home from "screens/home";
import ItemWiki from "screens/ItemWikiPage";
import DataProvider from "./context";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <DataProvider>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </DataProvider>
  );
}

export default App;
