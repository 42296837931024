import React, { Component } from "react";
import { MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBBtn } from "mdbreact";
import Lottie from "react-lottie";
import * as flowerLoading from "animations/flower_loading.json";

export default class LoadingDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isStopped: false,
      isPaused: false,
    };
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: flowerLoading.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <MDBModal isOpen={this.props.isOpen} toggle={this.props.onToggle} centered>
        <MDBModalBody>
          <Lottie
            options={defaultOptions}
            height={300}
            width={300}
            isStopped={false}
            isPaused={false}
            isClickToPauseDisabled={true}
          />
        </MDBModalBody>
      </MDBModal>
    );
  }
}
