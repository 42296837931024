import React, { Component } from "react";
import { MDBBtn } from "mdbreact";

export default class ItemDetailTable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { itemList, onShowDetail } = this.props;

    return (
      <table>
        <thead>
          <tr>
            <th>ICON</th>
            <th>NAME</th>
            <th>DESCRIPTION</th>
          </tr>
        </thead>
        <tbody>
          {itemList && itemList.length > 0 ? (
            itemList.map((item, idx) => (
              <tr key={idx}>
                <td>
                  <img src={`/assets/icon/${item.iconId}.png`} alt="No picture" />
                </td>
                <td>{item.name}</td>
                <td>
                  <MDBBtn gradient="peach" onClick={() => onShowDetail(idx)}>
                    Detail
                  </MDBBtn>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No Data</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}
