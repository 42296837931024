import React, { Component } from "react";
import { jobdbmap } from "data/jobdbmap";
import jobTypeListNew from "data/jobTypeListNew.json";
import ItemDetailDialog from "./dialogs/ItemDetailDialog";
import MonsterDetailDialog from "./dialogs/MonsterDetailDialog";
import "sass/item-detail-card.scss";

export default class ItemDetailCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenItemDetailDialog: false,
      isMonsterDialogOpen: false,
      selectMonsterId: 0,
      selectItemId: 0,
    };
  }

  buildCraftItem = (craftPotion) => {
    return (
      <div style={{ lineHeight: 1 }}>
        <img src={`assets/icon/${craftPotion.recvItem.iconId}.png`} />
        <span>
          {craftPotion.recvItem.name} (Request Sell Price {craftPotion.reqCegel} Cegels)
        </span>
      </div>
    );
  };

  buildApplicableJob = (jobId: number) => {
    if (jobId === 2097663 || jobId === 18874879 || jobId === 511) {
      return <span style={{ color: "#222" }}>สามารถสวมใส่ได้ทุกอาชีพ</span>;
    } else {
      return jobTypeListNew.jobTypeList.map((job) => {
        if (jobId & jobdbmap[parseInt(job.value)]) {
          return (
            <span className="mb-0" style={{ color: "#222" }}>
              {job.label.toUpperCase()}&emsp;
            </span>
          );
        }
      });
    }
  };

  buildItemDescription = (description: string) => {
    console.log(description);
    if (description === null || description === undefined) {
      return;
    }
    description = description.replaceAll("#N#", "\n\n");
    let unusedLabelIdx = description.indexOf("[คลิกขวาเพื่อดูการ์ด]");

    if (unusedLabelIdx != -1) {
      description = description.slice(0, unusedLabelIdx);
    }

    let splitR = description.split(/(#[^#]*#)/);
    let color = "#ffffff";

    return (
      <div style={{ lineHeight: 1 }}>
        {splitR.map((text, idx) => {
          switch (text) {
            case "#R#":
              color = "#ff0000";
              break;
            case "#G#":
              color = "#00ff00";
              break;
            case "#B#":
              color = "#ffffff";
              break;
            case "#X#":
              color = "#000000";
              break;
            default:
              return <span style={{ color: color, whiteSpace: "pre-wrap", fontSize: 16 }}>{text}</span>;
          }
        })}
      </div>
    );
  };

  render() {
    const { item } = this.props;
    const monster_sort = item?.dropBy?.sort((a, b) => (a.type > b.type) ? 1 : (a.type < b.type) ? -1 : 0 );

    const monster_fish = item?.fishing

    // console.log(item)

    return (
      <>
        <ItemDetailDialog
          isOpen={this.state.isOpenItemDetailDialog}
          onToggle={() => this.setState({ isOpenItemDetailDialog: !this.state.isOpenItemDetailDialog })}
          itemId={this.state.selectItemId}
        />
        <MonsterDetailDialog
          isOpen={this.state.isMonsterDialogOpen}
          onToggle={() => this.setState({ isMonsterDialogOpen: false })}
          monsterId={this.state.selectMonsterId}
        />
        <table className="item-table">
          <tbody>
            <tr className="item-title-row">
              <td colSpan={8}>
                <table className="title">
                  <tbody>
                    <th>
                      <img src={`assets/icon/${item.iconId}.png`} />
                      <span>{item.name} #{item.id}</span>
                    </th>
                  </tbody>
                </table>
              </td>
            </tr>
            <td className="item-row-divider" colSpan={8}>
              <div />
            </td>
            <tr className="item-detail-row">
              <th>TYPE</th>
              <td>
                <p>n/a</p>
              </td>
              <th>CLASS</th>
              <td>
                <p>n/a</p>
              </td>
              <th>BUY</th>
              <td>
                <p>{item.buyPrice}</p>
              </td>
              <th>SELL</th>
              <td>
                <p>{item.sellPrice}</p>
              </td>
            </tr>
            {item.type === 1 && (
              <>
                <tr className="item-detail-row">
                  <th>HP</th>
                  <td>
                    <p style={{ color: (item.hp < 0 ? "#ff0000" : (item.hp > 0 ? "#59CE8F" : "#ffffff")) }}>{item.hp}{item.setIdOpt === 1 ? "%" : ""}</p>
                    {/* <p>{item.hp}{item.setIdOpt === 1 ? "%" : ""}</p> */}
                  </td>
                  <th>AP</th>
                  <td>
                    <p style={{ color: (item.ap < 0 ? "#ff0000" : (item.ap > 0 ? "#277BC0" : "#ffffff")) }}>{item.ap}{item.setIdOpt === 1 ? "%" : ""}</p>
                    {/* <p>{item.ap}{item.setIdOpt === 1 ? "%" : ""}</p> */}
                  </td>
                  <th>DELAY (S)</th>
                  <td>
                    <p>{item.delay / 10.0}</p>
                  </td>
                </tr>
              </>
            )}
            {item.type === 0 && item.slotId === 100 && (
              <>
                <tr className="description-row">
                  <th>CRAFT</th>
                  <td colSpan={7}>{this.buildCraftItem(item.craftPotion)}</td>
                </tr>
              </>
            )}
            {((item.type !== 0 && item.slotId <= 11) || (item.type === 23 && item.extType === 7)) && (
              <>
                <td className="item-row-divider" colSpan={8}>
                  <div />
                </td>
                <tr className="item-status-row">
                  <th>LEVEL</th>
                  <td>
                    <p>{item.level}</p>
                  </td>
                  <th>REQ. STR</th>
                  <td>
                    <p>{item.reqStr}</p>
                  </td>
                  <th>REQ. AGI</th>
                  <td>
                    <p>{item.reqAgi}</p>
                  </td>
                  <th>REQ. INT</th>
                  <td>
                    <p>{item.reqInt}</p>
                  </td>
                </tr>
                <tr className="item-status-row">
                  <th>FAME</th>
                  <td>
                    <p>{item.fame}</p>
                  </td>
                  <th>REQ. VIT</th>
                  <td>
                    <p>{item.reqVit}</p>
                  </td>
                  <th>REQ. WIS</th>
                  <td>
                    <p>{item.reqWis}</p>
                  </td>
                  <th>REQ. LUK</th>
                  <td>
                    <p>{item.reqLuk}</p>
                  </td>
                </tr>
                <tr className="item-status-row">
                  <th>ATK</th>
                  <td>
                    <p>{item.atk}</p>
                  </td>
                  <th>DEF</th>
                  <td>
                    <p>{item.deff}</p>
                  </td>
                  <th>MPW</th>
                  <td>
                    <p>{item.mpw}</p>
                  </td>
                  <th>ASP</th>
                  <td>
                    <p>{item.aspd}</p>
                  </td>
                </tr>
                <tr className="item-status-row">
                  <th>DEX</th>
                  <td>
                    <p>{item.acc}</p>
                  </td>
                  <th>CRI</th>
                  <td>
                    <p>{item.crit}</p>
                  </td>
                  <th>EVR</th>
                  <td>
                    <p>{item.eva}</p>
                  </td>
                  <th>MSP</th>
                  <td>
                    <p>{item.mspd}</p>
                  </td>
                </tr>
                <tr className="item-status-row">
                  <th>DAMAGE INC.</th>
                  <td>
                    <p>{item.damageIncrease}</p>
                  </td>
                  <th>DAMAGE DEC.</th>
                  <td>
                    <p>{item.damageDecrease}</p>
                  </td>
                </tr>
                <td className="item-row-divider" colSpan={8}>
                  <div />
                </td>
                <tr className="application-job-row">
                  <th>APPLICABLE JOBS</th>
                  <td colSpan={7}>
                    <div className="applicable-job">{this.buildApplicableJob(item.jobId)}</div>
                  </td>
                </tr>
              </>
            )}
            <td className="item-row-divider" colSpan={8}>
              <div />
            </td>
            <tr className="evolution-row">
              <td colSpan={8}>
                {item.type === 22 && (
                  <table className="evolution-table" width="100%">
                    <tbody>
                      <tr>
                        <th>EVOLUTION</th>
                        <td colSpan={7}>
                          {item.petEvo.map((evoGroup, idx) => (
                            <>
                              <div className="evo-container">
                                <div className="evo-title">
                                  {evoGroup.evoRates.map((rate, idx) => (
                                    <button
                                      onClick={() => {
                                        this.setState({ isOpenItemDetailDialog: true, selectItemId: rate.itemId });
                                      }}
                                    >
                                      <img src={`./assets/icon/${rate.iconId}.png`} />
                                      <span>&ensp;({rate.rate}%)&ensp;</span>
                                    </button>
                                  ))}
                                </div>
                                <div className="evo-items">
                                  {evoGroup.items.map((item, idx) => (
                                    <button
                                      onClick={() => {
                                        this.setState({ isOpenItemDetailDialog: true, selectItemId: item.id });
                                      }}
                                    >
                                      <img src={`./assets/icon/${item.iconId}.png`} />
                                    </button>
                                  ))}
                                </div>
                                <div
                                  style={{
                                    height: idx !== item.petEvo.length - 1 ? 1 : 0,
                                    width: "100%",
                                    background: "#707070",
                                  }}
                                />
                              </div>
                            </>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </td>
            </tr>
            {item.type === 22 && (
              <td className="item-row-divider" colSpan={8}>
                <div />
              </td>
            )}
            <tr className="description-row">
              <th>DESCRIPTION</th>
              <td colSpan={7}>{this.buildItemDescription(item.description)}</td>
            </tr>
            <td className="item-row-divider" colSpan={8}>
              <div />
            </td>
            <tr className="drop-by-row">
              <th>DROPS BY</th>
              <td colSpan={7}>
                <div className="drop-by">
                  {monster_sort?.map((monster) => (
                    <button onClick={() => this.setState({ isMonsterDialogOpen: true, selectMonsterId: monster.id })} className={`type_${monster?.type}`}>
                      <span>
                        {monster.name} (LV. {monster.level})
                      </span>
                    </button>
                  ))}
                </div>
              </td>
            </tr>

            {item.fish_id !== 0 && (
            <>
            <td className="item-row-divider" colSpan={8}>
                  <div />
            </td>

            <tr className="drop-by-row">
              <th>FISHING</th>
              <td colSpan={7}>
                <div className="drop-by">
                  {monster_fish?.map((drop) => (
                      <div className="drop-detail">
                        <button
                          className="drop-detail-btn"
                          onClick={(e) =>
                                      this.setState({ isOpenItemDetailDialog: true, selectItemId: drop.item.id })
                                    }
                        >
                          <img src={`/assets/icon/${drop.item.iconId}.png`} />
                          <span>{drop.item.name}</span>
                          <span> ({drop.prob}%)</span>
                        </button>
                      </div>
                  ))}
                </div>
              </td>
            </tr></>) }

          </tbody>
        </table>
      </>
    );
  }
}
