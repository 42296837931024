import React, { Component } from "react";
import { DataContext } from "../../context";
import { MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBBtn } from "mdbreact";
import { jobdbmap } from "data/jobdbmap";
import jobTypeListNew from "data/jobTypeListNew.json";
import { Exception } from "ApiException";
import MonsterDetailDialog from "components/dialogs/MonsterDetailDialog";
import ItemDetailCard from "components/ItemDetailCard";
import "sass/item-detail-card.scss";
import "sass/item-detail-dialog.scss";

export default class ItemDetailDialog extends Component {
  static contextType = DataContext;

  constructor(props) {
    super(props);

    this.state = {
      item: {},
      isOpen: false,
      isStopped: false,
      isPaused: false,
      isOpenItemDetailDialog: false,
      isMonsterDialogOpen: false,
      selectItemId: 0,
      selectMonsterId: 0,
    };
  }

  handleOnGetItem = async () => {
    const { onFetchItemById } = this.context;

    let item_id = this.props?.itemId ?? 0;
    if(item_id > 0){
      let response = await onFetchItemById(item_id);

      if (response !== Exception.ConnectionError) {
        if(response.data.length > 0){
          this.setState({ item: response.data[0] });
        }
      }
    }
  };

  componentDidMount() {
    this.handleOnGetItem();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.itemId != this.props.itemId) {
      console.log(prevProps);
      this.handleOnGetItem(this.props.itemId);
    }
  }

  render() {
    const { item } = this.state;

    return (
      <>
        <MDBModal isOpen={this.props.isOpen} toggle={this.props.onToggle} centered>
          <MDBModalBody>
            <ItemDetailDialog
              isOpen={this.state.isOpenItemDetailDialog}
              onToggle={() => this.setState({ isOpenItemDetailDialog: !this.state.isOpenItemDetailDialog })}
              itemId={this.state.selectItemId}
            />
            <MonsterDetailDialog
              isOpen={this.state.isMonsterDialogOpen}
              onToggle={() => this.setState({ isMonsterDialogOpen: false })}
              monsterId={this.state.selectMonsterId}
            />
            {item !== null && (
              <div className="data-table" style={{ width: "50vw" }}>
                <div className="item-table-collection">
                  <ItemDetailCard item={item} />
                </div>
              </div>
            )}
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
}
