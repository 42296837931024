import React, { Component } from "react";
import axios from "axios";
import https from "https";
import { Exception } from "ApiException";
// import { equipmentTypeList } from "data/equipmentTypeList";

export const DataContext = React.createContext();

export default class DataProvider extends Component {
  // static BASEURL = "https://172.20.10.2:2083";
  static BASEURL = "https://sys.debug-dev.com:2083";

  constructor(props) {
    super(props);

    this.state = {
      mapCollection: [],
    };
  }

  onFetchItems = async (keyword: string, page: number, equipmentIds, jobId: number, itemType: string) => {
    let response = Exception.ConnectionError;

    await axios
      .post(`${DataProvider.BASEURL}/item`, {
        operation: "get_items",
        keyword: keyword,
        page: page,
        equipmentIds: equipmentIds,
        jobId: jobId,
        itemType: itemType,
      })
      .then((res) => {
        if (res.status == 200) response = res.data;
        else response = Exception.ConnectionError;
      })
      .catch((error) => {
        response = Exception.ConnectionError;
      });

    return response;
  };

  onFetchItemById = async (itemId: number) => {
    let response = Exception.ConnectionError;

    await axios
      .post(`${DataProvider.BASEURL}/item`, {
        operation: "get_item_by_id",
        itemId: [itemId],
      })
      .then((res) => {
        if (res.status == 200) response = res.data;
        else response = Exception.ConnectionError;
      })
      .catch((error) => {
        response = Exception.ConnectionError;
      });

    return response;
  };

  onFetchMonsters = async (name: string, minLevel: number, maxLevel: number, elementIdx: number, mapId: number, page: number) => {
    let response = {};

    await axios
      .post(`${DataProvider.BASEURL}/monster`, {
        operation: "get_monsters",
        name: name,
        minLevel: minLevel,
        maxLevel: maxLevel,
        elementIdx: elementIdx,
        mapId: mapId,
        page: page,
      })
      .then((res) => {
        if (res.status == 200) {
          response = res.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };

  onFetchMonstersById = async (monsterId: number) => {
    let response = {};

    await axios
      .post(`${DataProvider.BASEURL}/monster`, {
        operation: "get_monster_by_id",
        monsterId: monsterId,
      })
      .then((res) => {
        if (res.status == 200) {
          response = res.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };

  onFetchMapCollection = async () => {
    await axios
      .post(`${DataProvider.BASEURL}/map`, {
        operation: "get_map_collection",
      })
      .then((res) => {
        if (res.status == 200) {
          localStorage.setItem("mapCollection", JSON.stringify(res.data.data));
          this.setState({ mapCollection: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onGetPets = async (keyword: string, page: number) => {
    let response = Exception.ConnectionError;

    await axios
      .post(`${DataProvider.BASEURL}/pet`, {
        operation: "get_pets",
        keyword: keyword,
        page: page,
      })
      .then((res) => {
        response = res.data;
      })
      .catch((error) => {});

    return response;
  };

  componentDidMount() {
    const mapCollection = localStorage.getItem("mapCollection");
    if (mapCollection != null) this.setState({ maps: mapCollection });
  }

  componentWillUpdate() {
    localStorage.setItem("jobId", this.state.jobId);
  }

  render() {
    const { mapCollection } = this.state;
    const { onFetchItems, onFetchItemById, onFetchMonsters, onFetchMonstersById, onGetPets, onFetchMapCollection } = this;
    return (
      <DataContext.Provider
        value={{
          mapCollection,
          onFetchItems,
          onFetchItemById,
          onFetchMonsters,
          onFetchMonstersById,
          onGetPets,
          onFetchMapCollection,
        }}
      >
        {this.props.children}
      </DataContext.Provider>
    );
  }
}
