import React, { Component } from "react";
import Select from "react-select";
import { DataContext } from "../context";
import { Pagination } from "@material-ui/lab";
import { MDBContainer, MDBModal, MDBCol, MDBIcon } from "mdbreact";
import NavBar from "components/NavBar";
import jobTypeList from "data/jobTypeList.json";
import LoadingDialog from "components/dialogs/LoadingDialog";
import MonsterDetailDialog from "components/dialogs/MonsterDetailDialog";
import { jobdbmap } from "data/jobdbmap";
import jobTypeListNew from "data/jobTypeListNew.json";
import itemTypeList from "data/itemTypeList.json";
import optionStatusList from "data/optionStatusList.json";
import ItemDetailTable from "components/itemDetailTable";
import ItemDetailDialog from "components/dialogs/ItemDetailDialog";
import { Exception } from "ApiException";
import { colorStyles } from "../components/colorStyles";
import { equipmentTypeList, cardSlotType } from "data/equipmentTypeList";
import "../sass/item_wiki_page.scss";
import ItemDetailCard from "components/ItemDetailCard";

export default class ItemWikiPage extends Component {
  static contextType = DataContext;

  constructor(props) {
    super(props);

    this.state = {
      isOpenItemDetailDialog: false,
      isMonsterDialogOpen: false,
      itemType: "All",
      keyword: "",
      selectItemId: 0,
      selectMonsterId: 0,
      totalPage: 1,
      page: 1,
      jobId: null,
      equipmentIds: [],
      filterItems: [],
      equipmentOptions: equipmentTypeList,
      colourStyles: colorStyles,
    };
  }

  handleItemDetailToggle = (id) => {
    this.setState((prevState, _) => ({
      selectItemId: id ?? 0,
      isOpenItemDetailDialog: !this.state.isOpenItemDetailDialog,
    }));
  };

  handleOnFilterChange = async (keyword: string, page: number, equipmentIds, jobId: number, itemType: string) => {
    const { onFetchItems } = this.context;
    let filterKeyword = keyword ?? this.state.keyword;
    let filterPage = page ?? this.state.page;
    let filterEquipmentIds = equipmentIds ?? this.state.equipmentIds;
    let filterJobId = jobId ?? this.state.jobId;
    let filterItemType = itemType ?? this.state.itemType;

    let response = await onFetchItems(filterKeyword, filterPage, filterEquipmentIds, filterJobId, filterItemType);

    if (response === Exception.ConnectionError) {
      this.setState({ isAlert: true, alertText: Exception.ConnectionError });
    } else {
      this.setState({ filterItems: response.data.items, totalPage: response.data.totalPage });
    }
  };

  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  loadParamUrl = async () => {
      await this.sleep(200);

      let qs = document.location.search;
      qs = qs.split('+').join(' ');
      var params = {},
          tokens,
          re = /[?&]?([^=]+)=([^&]*)/g;

      while (tokens = re.exec(qs)) {
          params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
      }

      console.log(params);
    
      if(params?.item !== undefined){
        this.setState({ keyword: params?.item, page: 1 });
        this.handleOnFilterChange(params?.item, 1, null, null, null);
      }
      else{
        this.handleOnFilterChange();
      }

    return null;
  }


  componentDidMount() {
    this.loadParamUrl();
  }

  render() {
    const { fetchItemList } = this.context;
    const { equipmentOptions, selectItemId, page, items, filterItems, totalPage } = this.state;

    return (
      <>
        <ItemDetailDialog
          isOpen={this.state.isOpenItemDetailDialog}
          onToggle={() => this.setState({ isOpenItemDetailDialog: !this.state.isOpenItemDetailDialog })}
          itemId={this.state.selectItemId}
        />
        <MDBContainer className="item-wiki-container" fluid>
          <MonsterDetailDialog
            isOpen={this.state.isMonsterDialogOpen}
            onToggle={() => this.setState({ isMonsterDialogOpen: false })}
            monsterId={this.state.selectMonsterId}
          />
          <div className="item-type-filter">
            <div className="filter-title">
              <span>ITEM TYPE</span>
            </div>
            <div className="search-item-options">
              <Select
                defaultValue={itemTypeList.itemTypeList[0]}
                options={itemTypeList.itemTypeList}
                getOptionLabel={(option) => option.label.toUpperCase()}
                onChange={(option) => {
                  this.setState({ itemType: option.value, jobId: null, equipmentIds: [], page: 1 });
                  this.handleOnFilterChange(null, 1, null, null, option.value);
                }}
              />
            </div>
          </div>
          {this.state.itemType === "Equipment" && (
            <div className="job-filter">
              <div className="filter-title">
                <span>JOB</span>
              </div>
              <div className="search-item-options">
                <Select
                  defaultValue={jobTypeList.jobTypeList[0]}
                  options={jobTypeList.jobTypeList}
                  getOptionLabel={(option) => option.label.toUpperCase()}
                  onChange={(option) => {
                    this.setState({ page: 1, jobId: option.value });
                    this.handleOnFilterChange(null, 1, null, option.value, null);
                  }}
                />
              </div>
            </div>
          )}
          {/* SLOT TYPE FILTER */}
          {this.state.itemType === "Equipment" || this.state.itemType.includes("Card") ? (
            <div className="slot-filter">
              <div className="filter-title">
                <span>SLOT</span>
              </div>
              <div className="search-item-options">
                <Select
                  isMulti
                  options={!this.state.itemType.includes("Card") ? equipmentOptions : cardSlotType}
                  getOptionLabel={(option) => option.label.toUpperCase()}
                  style={this.colourStyles}
                  onChange={(options) => {
                    let filterEquipmentIds = options.map((option) => option.value);
                    this.setState({ page: 1, equipmentIds: filterEquipmentIds });
                    this.handleOnFilterChange(null, 1, filterEquipmentIds, null, null);
                  }}
                />
              </div>
            </div>
          ) : (
            <div />
          )}
          {/* NAME FILTER */}
          <div className="name-filter">
            <div className="filter-title">
              <span>ITEM NAME</span>
            </div>
            <div className="item-filter-input">
              <form className="form-inline mt-4 mb-4">
                <input
                  className="form-control form-control ml-3 w-75"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  defaultValue={this?.state?.keyword}
                  onChange={(e) => {
                    this.setState({ keyword: e.target.value, page: 1 });
                    this.handleOnFilterChange(e.target.value, 1, null, null, null);
                  }}
                />
              </form>
            </div>
          </div>
          {/* ITEM DETAIL CARD */}
          <div className="data-table">
            <table className="item-table-collection">
              {filterItems.map((item, idx) => (
                <ItemDetailCard item={item} />
              ))}
            </table>

            <Pagination
              count={totalPage}
              color="primary"
              page={page}
              onChange={(evt, page) => {
                let currentPage = parseInt(page);
                this.setState({ page: currentPage });
                this.handleOnFilterChange(null, currentPage, null, null, null);
              }}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "3vh",
              }}
            />
          </div>
          <footer>
            <br />
            <div className="footer-options"></div>
          </footer>
        </MDBContainer>
      </>
    );
  }
}
