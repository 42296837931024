import React, { Component } from "react";
import Select from "react-select";
import { DataContext } from "../context";
import { Pagination } from "@material-ui/lab";
import { MDBContainer, MDBModal, MDBCol, MDBIcon } from "mdbreact";
import NavBar from "components/NavBar";
import jobTypeList from "data/jobTypeList.json";
import LoadingDialog from "components/dialogs/LoadingDialog";
import MonsterDetailDialog from "components/dialogs/MonsterDetailDialog";
import { jobdbmap } from "data/jobdbmap";
import jobTypeListNew from "data/jobTypeListNew.json";
import itemTypeList from "data/itemTypeList.json";
import optionStatusList from "data/optionStatusList.json";
import ItemDetailTable from "components/itemDetailTable";
import ItemDetailDialog from "components/dialogs/ItemDetailDialog";
import { Exception } from "ApiException";
import { colorStyles } from "../components/colorStyles";
import { equipmentTypeList, cardSlotType } from "data/equipmentTypeList";
import "../sass/item_wiki_page.scss";
import ItemDetailCard from "components/ItemDetailCard";

export default class PetWikiPage extends Component {
  static contextType = DataContext;

  constructor(props) {
    super(props);

    this.state = {
      isOpenItemDetailDialog: false,
      isMonsterDialogOpen: false,
      keyword: "",
      totalPage: 1,
      page: 1,
      selectMonsterId: 0,
      filterPets: [],
      colourStyles: colorStyles,
    };
  }

  handleItemDetailToggle = (id) => {
    this.setState((prevState, _) => ({
      selectItemId: id ?? 0,
      isOpenItemDetailDialog: !this.state.isOpenItemDetailDialog,
    }));
  };

  handleOnFilterChange = async (keyword: string, page: number) => {
    const { onGetPets } = this.context;
    let filterKeyword = keyword ?? this.state.keyword;
    let filterPage = page ?? this.state.number;

    let response = await onGetPets(filterKeyword, filterPage);

    if (response === Exception.ConnectionError) {
      this.setState({ isAlert: true, alertText: Exception.ConnectionError });
    } else {
      this.setState({ filterPets: response.data.items, totalPage: response.data.totalPage });
    }
  };

  componentDidMount() {
    this.handleOnFilterChange("", 1);
  }

  render() {
    const { fetchItemList } = this.context;
    const { filterPets, totalPage, page } = this.state;

    return (
      <>
        <ItemDetailDialog
          isOpen={this.state.isOpenItemDetailDialog}
          onToggle={() => this.setState({ isOpenItemDetailDialog: !this.state.isOpenItemDetailDialog })}
          itemId={this.state.selectItemId}
        />
        <MDBContainer className="item-wiki-container" fluid>
          <MonsterDetailDialog
            isOpen={this.state.isMonsterDialogOpen}
            onToggle={() => this.setState({ isMonsterDialogOpen: false })}
            monsterId={this.state.selectMonsterId}
          />
          {/* NAME FILTER */}
          <div className="name-filter">
            <div className="filter-title">
              <span>ITEM NAME</span>
            </div>
            <div className="item-filter-input">
              <form className="form-inline mt-4 mb-4">
                <input
                  className="form-control form-control ml-3 w-75"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={(e) => {
                    this.setState({ keyword: e.target.value, page: 1 });
                    this.handleOnFilterChange(e.target.value, 1, null, null, null);
                  }}
                />
              </form>
            </div>
          </div>
          {/* ITEM DETAIL CARD */}
          <div className="data-table">
            <table className="item-table-collection">
              {filterPets.map((item, idx) => (
                <ItemDetailCard item={item} />
              ))}
            </table>

            <Pagination
              count={totalPage}
              color="primary"
              page={page}
              onChange={(evt, page) => {
                let currentPage = parseInt(page);
                this.setState({ page: currentPage });
                this.handleOnFilterChange(null, currentPage);
              }}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "3vh",
              }}
            />
          </div>
          <footer>
            <br />
            <div className="footer-options"></div>
          </footer>
        </MDBContainer>
      </>
    );
  }
}
