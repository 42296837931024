import React, { Component } from "react";
import Select from "react-select";
import { DataContext } from "../context";
import { Pagination } from "@material-ui/lab";
import { MDBContainer, MDBModal, MDBCol, MDBIcon } from "mdbreact";
import NavBar from "components/NavBar";
import jobTypeList from "data/jobTypeList.json";
import LoadingDialog from "components/dialogs/LoadingDialog";
import MonsterDetailDialog from "components/dialogs/MonsterDetailDialog";
import { jobdbmap } from "data/jobdbmap";
import jobTypeListNew from "data/jobTypeListNew.json";
import itemTypeList from "data/itemTypeList.json";
import optionStatusList from "data/optionStatusList.json";
import ItemDetailTable from "components/itemDetailTable";
import ItemDetailDialog from "components/dialogs/ItemDetailDialog";
import { Exception } from "ApiException";
import { colorStyles } from "../components/colorStyles";
import { equipmentTypeList, cardSlotType } from "data/equipmentTypeList";
import "../sass/item_wiki_page.scss";
import ItemDetailCard from "components/ItemDetailCard";

export default class SkillPage extends Component {
  static contextType = DataContext;

  constructor(props) {
    super(props);

    this.state = {
      isOpenItemDetailDialog: false,
      isMonsterDialogOpen: false,
      keyword: "",
      totalPage: 1,
      page: 1,
      selectMonsterId: 0,
      filterPets: [],
      colourStyles: colorStyles,
    };
  }

  handleItemDetailToggle = (id) => {
    this.setState((prevState, _) => ({
      selectItemId: id ?? 0,
      isOpenItemDetailDialog: !this.state.isOpenItemDetailDialog,
    }));
  };

  handleOnFilterChange = async (keyword: string, page: number) => {
    const { onGetPets } = this.context;
    let filterKeyword = keyword ?? this.state.keyword;
    let filterPage = page ?? this.state.number;

    let response = await onGetPets(filterKeyword, filterPage);

    if (response === Exception.ConnectionError) {
      this.setState({ isAlert: true, alertText: Exception.ConnectionError });
    } else {
      this.setState({ filterPets: response.data.items, totalPage: response.data.totalPage });
    }
  };

  componentDidMount() {
    this.handleOnFilterChange("", 1);
  }

  render() {
    const { fetchItemList } = this.context;
    const { filterPets, totalPage, page } = this.state;

    return (
      <>
        
        <MDBContainer className="item-wiki-container" fluid>
          <div className="frame">
            <iframe src="https://member.duran-seal.com/skill/" className="frameEm" Width="100%" Height="600px" FrameBorder="0" />
          </div>
          <footer>
            <br />
            <div className="footer-options"></div>
          </footer>
        </MDBContainer>
      </>
    );
  }
}
