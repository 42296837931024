export const jobdbmap = {
  0: 0b00000000000000000000000000000001,
  1: 0b00000000000000000000000000000010,
  2: 0b00000000000000000000000000000100,
  3: 0b00000000000000000000000000001000,
  4: 0b00000000000000000000000000010000,
  5: 0b00000000000000000000000000100000,
  6: 0b00000000000000000000000001000000,
  7: 0b00000000000000000000000010000000,
  8: 0b00000000000000000000000100000000,
  11: 0b00000000000000000000001000000000,
  21: 0b00000000000000000000010000000000,
  12: 0b00000000000000000000100000000000,
  22: 0b00000000000000000001000000000000,
  13: 0b00000000000000000010000000000000,
  23: 0b00000000000000000100000000000000,
  14: 0b00000000000000001000000000000000,
  24: 0b00000000000000010000000000000000,
  15: 0b00000000000000100000000000000000,
  25: 0b00000000000001000000000000000000,
  16: 0b00000000000010000000000000000000,
  28: 0b00000000000100000000000000000000,
  9: 0b00000000001000000000000000000000,
  19: 0b00000000010000000000000000000000,
  29: 0b00000000100000000000000000000000,
};
