import React, { Component } from "react";
import { DataContext } from "../../context";
import { MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBBtn } from "mdbreact";
import { Exception } from "ApiException";
import ItemDetailDialog from "./ItemDetailDialog";
import monsterElementsConvert from "data/monsterElementsConvert.json";
import "sass/monster_detail_dialog.scss";

export default class MonsterDetailDialog extends Component {
  static contextType = DataContext;

  constructor(props) {
    super(props);

    this.state = {
      monster: {},
      isOpen: false,
      isStopped: false,
      isPaused: false,
      isOpenItemDetailDialog: false,
      selectItemId: 0,
    };
  }

  handleOnFetchMonster = async () => {
    const { onFetchMonstersById } = this.context;

    if(this.props.monsterId > 0)
    {
      let response = await onFetchMonstersById(this.props.monsterId);

      if (response !== Exception.ConnectionError) {
        this.setState({ monster: response.data });
      }
    }
  };

  handleOnConvertMonsterElement = () => {
    const { monster } = this.state;
    if (monster != null) {
      if ("status" in monster) return monsterElementsConvert.elements[monster.status].toUpperCase();
    }
  };

  handleOnDisplayMonsterLocation = () => {
    const { monster } = this.state;

    if (monster != null) {
      if ("mapList" in monster) {
        return monster.mapList.map((map) => (
          <div className="map">
            <img src={"assets/map-marker-icon.png"} height="20" />
            <span>{map.name},&emsp;</span>
          </div>
        ));
      }
    }
  };

  handleOnDisplayMonsterElement = () => {
    const { monster } = this.state;

    if (monster != null) {
      if ("elementResistance" in monster) {
        return (
          <table style={{ width: "100%" }}>
            {monster.elementResistance.map((resistance, idx) => this.elementResistanceItem(monsterElementsConvert.elements[idx], resistance))}
          </table>
        );
      }
    }
  };

  handleOnDisplayMonsterDrop1 = () => {
    const { monster } = this.state;

    if (monster != null) {
      if ("drop1" in monster) {
        return monster.drop1.map((drop) => {
          return (
            <>
              <div className="drop-detail">
                <button className="drop-detail-btn" onClick={(e) => this.setState({ isOpenItemDetailDialog: true, selectItemId: drop.item.id })}>
                  <img src={`/assets/icon/${drop.item.iconId}.png`} />
                  <span>{drop.item.name}</span>
                  <span>&ensp;(&ensp;{drop.prob} %&ensp;)</span>
                </button>
              </div>
            </>
          );
        });
      }
    }
  };
  handleOnDisplayMonsterDrop2 = () => {
    const { monster } = this.state;

    if (monster != null) {
      if ("drop2" in monster) {
        return monster.drop2.map((drop) => {
          return (
            <>
              <div className="drop-detail">
                <button className="drop-detail-btn" onClick={(e) => this.setState({ isOpenItemDetailDialog: true, selectItemId: drop.item.id })}>
                  <img src={`/assets/icon/${drop.item.iconId}.png`} />
                  <span>{drop.item.name}</span>
                  <span>&ensp;(&ensp;{drop.prob} %&ensp;)</span>
                </button>
              </div>
            </>
          );
        });
      }
    }
  };
  handleOnDisplayMonsterDrop3 = () => {
    const { monster } = this.state;

    if (monster != null) {
      if ("drop3" in monster) {
        return monster.drop3.map((drop) => {
          return (
            <>
              <div className="drop-detail">
                <button className="drop-detail-btn" onClick={(e) => this.setState({ isOpenItemDetailDialog: true, selectItemId: drop.item.id })}>
                  <img src={`/assets/icon/${drop.item.iconId}.png`} />
                  <span>{drop.item.name}</span>
                  <span>&ensp;(&ensp;{drop.prob} %&ensp;)</span>
                </button>
              </div>
            </>
          );
        });
      }
    }
  };

  elementResistanceItem = (element: string, resistance: string) => {
    let color = "white";

    if (parseInt(resistance) > 100) color = "green";
    else if (parseInt(resistance) < 100) color = "red";

    return (
      <tr>
        <th>{element}</th>
        <td>
          <p style={{ color: color }}>{resistance} %</p>
        </td>
      </tr>
    );
  };

  componentDidMount() {
    this.handleOnFetchMonster();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.monsterId != this.props.monsterId) {
      this.handleOnFetchMonster(this.props.monsterId);
    }
  }

  render() {
    const { monster } = this.state;

    return (
      <MDBModal isOpen={this.props.isOpen} toggle={this.props.onToggle} centered>
        <MDBModalBody>
          <ItemDetailDialog
            isOpen={this.state.isOpenItemDetailDialog}
            onToggle={() => this.setState({ isOpenItemDetailDialog: !this.state.isOpenItemDetailDialog })}
            itemId={this.state.selectItemId}
          />
          {"name" in monster && (
            <table className="monster-detail-table">
              <tbody>
                <tr className="monster-title-row">
                  <th>{monster?.name ?? ""} #{monster?.id ?? ""}</th>
                  <th>ON MAP</th>
                  <th>ELEMENT</th>
                </tr>
                <td className="monster-wiki-divider" colSpan={4}>
                  <div />
                </td>
                <tr className="monster-detail-row">
                  <td>
                    <table className="monster-status-col" height="100%" width="100%">
                      <tbody>
                        <tr>
                          <th>HP</th>
                          <td>
                            <p>{monster.hp}</p>
                          </td>
                          <td colSpan={2} rowSpan={6} style={{ background: "white", borderRadius: 7, paddingLeft: 15 }}>
                            <img src={`/assets/monster_model/${monster.modelId}.gif`} />
                          </td>
                        </tr>
                        <tr>
                          <th>LEVEL</th>
                          <td>
                            <p>{monster.level}</p>
                          </td>
                        </tr>
                        <tr>
                          <th>RANGE</th>
                          <td>
                            <p>{monster.range}</p>
                          </td>
                        </tr>
                        <tr>
                          <th>ELEMENT</th>
                          <td>
                            <p>{monsterElementsConvert.elements[monster.status].toUpperCase()}</p>
                          </td>
                        </tr>
                        <tr>
                          <th>CRI</th>
                          <td>
                            <p>{monster.critical}</p>
                          </td>
                        </tr>
                        <tr>
                          <th>DEX</th>
                          <td>
                            <p>{monster.hit}</p>
                          </td>
                        </tr>
                        <tr>
                          <th>EVR</th>
                          <td>
                            <p>{monster.evasion}</p>
                          </td>
                        </tr>
                        <tr>
                          <th>ATK</th>
                          <td>
                            <p>{monster.attack}</p>
                          </td>
                          <th>REBIRTH (S)</th>
                          <td>
                            <p>{monster.rebirth}</p>
                          </td>
                        </tr>
                        <tr>
                          <th>DEF</th>
                          <td>
                            <p>{monster.defence}</p>
                          </td>
                          <th>BASE EXP</th>
                          <td>
                            <p>{monster.exp}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td className="monster-location-col" colSpan={1}>
                    <div className="location">
                      {monster.mapList.map((map) => (
                        <div className="map">
                          <img src={"assets/map-marker-icon.png"} height="20" />
                          <span>{map.name}&emsp;</span>
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="monster-element-col" colSpan={2}>
                    <table width="100%">
                      <tbody>
                        {monster.elementResistance.map((resistance, idx) => this.elementResistanceItem(monsterElementsConvert.elements[idx], resistance))}
                      </tbody>
                    </table>
                  </td>
                </tr>
                <td className="monster-wiki-divider" colSpan={4}>
                  <div />
                </td>
                <tr>
                  <td colSpan={4}>
                    <table className="drop1-table" width="100%">
                      <tbody>
                        <tr>
                          <th colSpan={3}>COMMON, ITEM , POTION ( 80% )</th>
                        </tr>
                        <tr>
                          <div className="common-drop-table">
                            {monster.drop1.map((drop) => {
                              return (
                                <>
                                  <div className="drop-detail">
                                    <button
                                      className="drop-detail-btn"
                                      onClick={(e) => this.setState({ isOpenItemDetailDialog: true, selectItemId: drop.item.id })}
                                    >
                                      <img src={`/assets/icon/${drop.item.iconId}.png`} />
                                      <span>
                                        {drop.item.name} ({drop.prob} %)
                                      </span>
                                    </button>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <td className="monster-wiki-divider" colSpan={4}>
                  <div />
                </td>
                <tr>
                  <td colSpan={4}>
                    <table>
                      <tbody>
                        <tr>
                          <th colSpan={4}>GEM, SCROLL, CARD ( 10% )</th>
                        </tr>
                        <tr>
                          <div className="common-drop-table">
                            {monster.drop2.map((drop) => {
                              return (
                                <>
                                  <div className="drop-detail">
                                    <button
                                      className="drop-detail-btn"
                                      onClick={(e) => this.setState({ isOpenItemDetailDialog: true, selectItemId: drop.item.id })}
                                    >
                                      <img src={`/assets/icon/${drop.item.iconId}.png`} />
                                      <span>{drop.item.name}</span>
                                      <span> ({drop.prob}%)</span>
                                    </button>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <td className="monster-wiki-divider" colSpan={4}>
                  <div />
                </td>
                <tr>
                  <td colSpan={4}>
                    <table>
                      <tbody>
                        <tr>
                          <th colSpan={4}>EQUIPMENT ( 10% )</th>
                        </tr>
                        <tr>
                          <div className="common-drop-table">
                            {monster.drop3.map((drop) => {
                              return (
                                <>
                                  <div className="drop-detail">
                                    <button
                                      className="drop-detail-btn"
                                      onClick={(e) => this.setState({ isOpenItemDetailDialog: true, selectItemId: drop.item.id })}
                                    >
                                      <img src={`/assets/icon/${drop.item.iconId}.png`} />
                                      <span>{drop.item.name}</span>
                                      <span>&ensp;({drop.prob}%)</span>
                                    </button>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </MDBModalBody>
      </MDBModal>
    );
  }
}
